<template>
  <div>
    <CRow>
      <CCol sm="6">
        <CInput
          label="Name"
          v-model="dataName"
          required
          placeholder="Geben Sie den Namen des Realms ein"
        />
      </CCol>
      <CCol sm="6">
        <CSelect
          label="Organisation"
          :value="dataOrganizationId"
          @update:value="dataOrganizationId = $event"
          required
          :options="organizations"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="6">
        <CInput
          label="Wordpress URL"
          v-model="dataWordpressUrl"
          required
          placeholder="https://..."
        />
      </CCol>
      <CCol sm="6">
        <CInput
          label="Erlaubte Domains"
          v-model="dataAllowedDomains"
          required
          description="Bitte geben Sie die erlaubten Domains in einer durch Komma getrennten Weise ein, welche Ihren Leadgenerator einbetten dürfen."
          placeholder="beispiel.de, www.beispiel.de, webseite.org"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="3">
        <CInput
          label="Lizenz (API-Key)"
          v-model="dataLicense"
          required
          placeholder="Leadgen-XXXXXX"
        />
      </CCol>
      <CCol sm="3">
        <CInput
          label="Stripe Subscription ID"
          v-model="dataStripeSubscriptionId"
          required
          placeholder="Geben Sie diese ID von Stripe ein"
        />
      </CCol>
      <CCol sm="3">
        <CSelect
          label="Status"
          :value="dataStatus"
          @update:value="dataStatus = $event"
          required
          :options="['ACTIVE', 'INACTIVE', 'PENDING', 'BANNED']"
        />
      </CCol>
      <CCol sm="3">
        <CSelect
          label="Tarif"
          :value="dataPlan"
          @update:value="dataPlan = $event"
          required
          :options="Object.keys($commons.REALM_PLANS)"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <CButton v-if="!processing" @click="clickHandler" color="success">
          {{ btnText }}
        </CButton>
        <CButton v-else disabled color="primary">
          Verarbeitung...
        </CButton>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'RealmForm',
  props: {
    name: {
      type: String,
      default: ''
    },
    wordpressUrl: {
      type: String,
      default: ''
    },
    license: {
      type: String,
      default: ''
    },
    status: {
      type: String,
      default: 'ACTIVE',
      validator: function (value) {
        return ['ACTIVE', 'INACTIVE', 'PENDING', 'BANNED'].indexOf(value) !== -1
      }
    },
    allowedDomains: {
      type: String,
      default: ''
    },
    btnText: {
      type: String,
      required: true
    },
    organizationId: {
      type: [String, Number],
      default: 1
    },
    plan: {
      type: String,
      default: 'TRIAL'
    },
    stripeSubscriptionId: {
      type: String
    },
    processing: Boolean
  },
  data() {
    return {
      dataName: this.name,
      dataWordpressUrl: this.wordpressUrl,
      dataLicense: this.license,
      dataStatus: this.status,
      dataAllowedDomains: this.allowedDomains,
      dataOrganizationId: this.organizationId || 1,
      dataPlan: this.plan,
      dataStripeSubscriptionId: this.stripeSubscriptionId,
      organizations: []
    }
  },
  mounted () {
    axios.get('/organization')
        .then(response => {
          this.organizations = response.data.map(organization => {
            return {value: organization.id, label: `${organization.id} - ${organization.name}`}
          })
        })
  },
  methods: {
    clickHandler() {
      this.$emit('submit', {
        name: this.dataName,
        wordpressUrl: this.dataWordpressUrl,
        license: this.dataLicense,
        status: this.dataStatus,
        allowedDomains: this.dataAllowedDomains,
        organizationId: this.dataOrganizationId,
        plan: this.dataPlan,
        stripeSubscriptionId: this.dataStripeSubscriptionId
      })
    }
  }
}
</script>
